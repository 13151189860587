// VendorLeftSection.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { FaShoppingBag, FaClipboardList } from 'react-icons/fa'; // Import icons for vendor options
import { RiLogoutCircleLine } from "react-icons/ri";

const VendorLeftSection = ({ handleOptionClick }) => {
  const handleLogout = () => {
    // Clear the admin token from localStorage
    localStorage.removeItem('vendorToken');
    // Redirect to the home page
  };

  return (
    <div className="w-1/6 h-[100vh] p-4 bg-gradient-to-r from-[#FB8802] to-[#FFE649]">
      <h2 className="text-lg font-bold mb-4 text-black">Options</h2>
      <ul className="text-[18px]">
        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-[#FFE649] hover:text-black transform hover:scale-105">
          <Link to="/vendordashboard/coupons" className="flex items-center" onClick={() => handleOptionClick('coupons')}>
            <FaShoppingBag /> &nbsp; Coupons
          </Link>
        </li>
        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-[#FFE649] hover:text-black transform hover:scale-105">
          <Link to="/vendordashboard/couponexpiry" className="flex items-center" onClick={() => handleOptionClick('couponexpiry')}>
            <FaClipboardList /> &nbsp; Coupon expiry
          </Link>
        </li>
        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-[#FFE649] hover:text-black transform hover:scale-105">
          <Link to="/vendordashboard/products" className="flex items-center" onClick={() => handleOptionClick('products')}>
            <FaClipboardList /> &nbsp; Your products
          </Link>
        </li>
        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-[#FFE649] hover:text-black transform hover:scale-105">
          <Link to="/vendordashboard/addproduct" className="flex items-center" onClick={() => handleOptionClick('addproduct')}>
            <FaClipboardList /> &nbsp; Add Product
          </Link>
        </li>
        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-[#FFE649] hover:text-black transform hover:scale-105">
          <Link to="/vendordashboard/createsmartdeals" className="flex items-center" onClick={() => handleOptionClick('createsmartdeals')}>
            <FaClipboardList /> &nbsp; Create Deal
          </Link>
        </li>
        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-[#FFE649] hover:text-black transform hover:scale-105">
          <Link to="/vendordashboard/smartdeals" className="flex items-center" onClick={() => handleOptionClick('smartdeals')}>
            <FaClipboardList /> &nbsp; Smart Deals
          </Link>
        </li>
        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-[#FFE649] hover:text-black transform hover:scale-105">
          <Link to="/" className="flex items-center" onClick={handleLogout}>
            <RiLogoutCircleLine /> &nbsp; Log Out
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default VendorLeftSection;
