import React, { useState } from "react";
import axiosClient from '../components/AxiosClient';

const Createsmartdeals = () => {
  const [productId, setProductId] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Retrieve vendor token from local storage
      const vendorToken = localStorage.getItem("vendorToken");

      // Check if vendor token exists
      if (vendorToken) {
        // Create a FormData object and append form values
        const formData = new FormData();
        formData.append('product_id', productId);
        formData.append('discount_percentage', discountPercentage);
        formData.append('start_date', new Date(startDate).toISOString());
        formData.append('end_date', new Date(endDate).toISOString());

        const response = await axiosClient.post(
          "smartdeals/create_deal",
          formData,
          {
            headers: {
              Authorization: `Bearer ${vendorToken}`,
              'Content-Type': 'multipart/form-data'
            },
          }
        );
        setMessage("Deal created successfully!");
      } else {
        console.error("Vendor token not found in local storage");
        setMessage("Vendor token not found. Please log in.");
      }
    } catch (error) {
      console.error("Error creating deal:", error);
      setMessage("Error creating deal: " + error.message);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="text-3xl font-bold mb-6 text-black">Create Smart Deal</h1>
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-full max-w-md">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Product ID:
          </label>
          <input
            type="text"
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-[#FB8802] focus:ring-opacity-50" // Updated focus color
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Discount Percentage:
          </label>
          <input
            type="number"
            value={discountPercentage}
            onChange={(e) => setDiscountPercentage(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-[#FB8802] focus:ring-opacity-50" // Updated focus color
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Start Date:
          </label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-[#FB8802] focus:ring-opacity-50" // Updated focus color
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            End Date:
          </label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-[#FB8802] focus:ring-opacity-50" // Updated focus color
          />
        </div>
        <button
          type="submit"
          className="w-full bg-[#FB8802] text-white py-2 rounded hover:bg-[#FFE649] transition duration-200"
        >
          Create Deal
        </button>
      </form>
      {message && <p className="mt-4 text-lg text-green-600">{message}</p>}
    </div>
  );
};

export default Createsmartdeals;
