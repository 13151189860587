// VendorDashboard.jsx
import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom'; // Import Routes
import VendorLeftSection from './VendorLeftSection'; // Import VendorLeftSection component
import vendorContext from '../context/vendor/vendorContext'; // Import vendor context

const VendorDashboard = ({ children }) => {
  const { vendorName } = useContext(vendorContext); // Assuming vendorName is stored in context

  const handleOptionClick = (option) => {
    // Handle option click if needed
  };

  return (
    <div className="flex min-h-screen border-l-4 border-[#FB8802]"> {/* Left border color */}
      {/* Left Section */}
      <VendorLeftSection handleOptionClick={handleOptionClick} />

      {/* Right Section */}
      <div className="w-5/6 flex flex-col">
        {/* Header */}
        <div className="h-14 bg-gradient-to-r from-[#FB8802] to-[#FFE649] flex justify-between items-center pr-4 text-4xl text-black shadow-md">
          <h1 className="text-xl font-semibold">Vendor Dashboard</h1>
          <div className="text-lg font-semibold text-black pr-4">{vendorName}</div> {/* Vendor name display */}
        </div>

        {/* Main Content Area */}
        <div className="flex-grow p-4 bg-white rounded-lg shadow-md">
          {/* Use Routes to render child routes */}
          <Routes>
            {children} {/* Render the child routes */}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default VendorDashboard;
